import React, { useLayoutEffect, useRef } from 'react';
import { FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import { motion } from 'framer-motion';
import './App.css';

const App = () => {
  const appointmentsRef = useRef(null);

  useLayoutEffect(() => {
    const { hash } = window.location;
    if (hash === '#appointments' && appointmentsRef.current) {
      appointmentsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 text-black dark:text-white">
      <header className="relative h-screen sm:h-[80vh]">
        <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1613048998814-4af1bd82fb01?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80')" }}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h1 className="text-4xl sm:text-5xl font-bold mb-4">Hi, I'm Omar! 👋🏽</h1>
            <p className="text-lg sm:text-xl mb-8">I live with my family in the heart of Silicon Valley, in sunny California!</p>
            <div className="flex flex-wrap gap-x-4 space-y-2 sm:space-y-0">
              <a href="mailto:omar@shaik.dev" className="bg-white text-black px-6 py-3 rounded-full hover:bg-gray-200 transition duration-300">Email me</a>
              <a href="https://www.linkedin.com/in/omarshaik" target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition duration-300 flex items-center">
                <FaLinkedin className="mr-2" /> LinkedIn
              </a>
              <a href="https://twitter.com/omarshaikdev" target="_blank" rel="noopener noreferrer" className="bg-blue-400 text-white px-6 py-3 rounded-full hover:bg-blue-500 transition duration-300 flex items-center">
                <FaTwitter className="mr-2" /> Twitter
              </a>
              <a href="https://www.instagram.com/omarshaikdev" target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white px-6 py-3 rounded-full hover:from-purple-600 hover:via-pink-600 hover:to-red-600 transition duration-300 flex items-center">
                <FaInstagram className="mr-2" /> Instagram
              </a>
            </div>
          </motion.div>
        </div>
      </header>

      <section className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl sm:text-4xl font-bold mb-8">Experience</h2>
          <p className="text-lg sm:text-xl mb-4">
            I'm building something new! Previously, I managed a few engineering teams distributed across the globe. Before that, I spent 5 years working on the Apple Vision Pro as both an engineer and a manager. A lifetime ago, I was an iOS engineer at pre-IPO Dropbox.
          </p>
          <p className="text-lg sm:text-xl">
            I'm available for mock interviews or career conversations, or to discuss any thorny problems you're facing with your team. <strong>Click on each session below to learn more!</strong>
          </p>
        </motion.div>
      </section>

      <section ref={appointmentsRef} className="container mx-auto px-4 py-8">
        <h2 className="text-3xl sm:text-4xl font-bold mb-8">Appointments</h2>
        <div className="bg-white aspect-w-16 aspect-h-9" style={{ height: '70vh', minHeight: '300px' }}>
          <iframe
            src="https://calendar.google.com/calendar/appointments/AcZssZ0ZGFZ-NRd0eXACuLHjygwxcZvfAp0_LNY-JmU=?gv=true"
            style={{ border: 0 }}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Google Calendar Appointment Scheduling"
          ></iframe>
        </div>
      </section>

      <section className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl sm:text-4xl font-bold mb-8">Top of Mind</h2>
          <p className="text-lg sm:text-xl">I've been enthralled by everything going on with AI. I haven't been this excited about a technology since the original iPhone! As an exploration, last summer I built <a href="https://www.genkode.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 dark:text-blue-400 hover:underline">genkode.com</a>, an AI-powered tool that generates code based on what you describe, and previews it.</p>
        </motion.div>
      </section>

      <footer className="bg-gray-800 dark:bg-black text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} The Shaik Estate LLC. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default App;
